import React from "react";
import goggle from "../assets/google.svg";
import apple from "../assets/apple.svg";
import logobottom from "../assets/logo-white.svg";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bgimageBlue2 mb-0 pt-lg-5 position-relative">
      <div className="container footer py-lg-1 px-lg-5 p-4 ">
        <div className="row">
          <div className="col-lg-3 col-6 ">
            <h6 className="text-white mb-4">About Itranxit </h6>

            <ul style={{ listStyleType: "none" }}>
              <li className="mb-3">
                <Link to={"/about"}>About us </Link>
              </li>{" "}
              <li className="mb-3">
                <Link to={"/contact"}>Contact us</Link>
              </li>{" "}
              <li className="mb-3">
                <Link to={"/#faqs"}>FAQs </Link>
              </li>
              <li className="mb-3">
                <Link to={"mailto:help@itranxit.com"}>Support </Link>
              </li>
            </ul>
          </div>{" "}
          <div className="col-lg-3 col-6 ">
            <h6 className="text-white mb-4">Deliver with Itranxit</h6>

            <ul style={{ listStyleType: "none" }}>
              <li className="mb-3">
                <Link target="_blank" to={"https://customer.itranxit.com/"}>
                  Customer
                </Link>
              </li>{" "}
              <li className="mb-3">
                <Link target="_blank" to={"https://fleet.itranxit.com/"}>
                  Fleet
                </Link>
              </li>{" "}
              <li className="mb-3">
                <Link target="_blank" to={"https://driver.itranxit.com/"}>
                  Driver
                </Link>
              </li>
            </ul>
          </div>{" "}
          <div className="col-lg-3 col-6">
            <h6 className="text-white mb-4">More information</h6>

            <ul style={{ listStyleType: "none", marginBottom:'26px' }}>
              <li className="mb-3">
                <Link to={"/privacy-policy"}>Privacy policy </Link>
              </li>{" "}
              <li className="mb-3">
                <Link to={"/terms-and-conditions"}>Terms and conditions</Link>
  </li>
  
            </ul>
       <h6 className="text-white mb-2">Address</h6>

                  <p className="mb-3 text-white" style={{fontSize:'14px',lineHeight:'22px'}}>
     5b Marconi Road,<br/>
Palmgroove Estate
Ilupeju Lagos</p>

          </div>
          <div className="col-lg-3 col-6 ">
            <h6 className="text-white mb-4">Download our mobile apps</h6>

            <div>
              <Link
                to={
                  "https://play.google.com/store/apps/details?id=com.iTranxitCustomer"
                }
                target="_blank"
              >
                <img src={goggle} height={40} alt="" className="me-lg-2 mb-1" />{" "}
              </Link>
              <img src={apple} height={40} alt="" />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end position-">
          <img src={logobottom} height={70} alt="" />
        </div>

        <hr />
        <div className="d-flex justify-content-between align-items-center my-lg-3 mt-3">
          <p className="mb-0 text-white">
            Copyright © {new Date().getFullYear()} iTranxit. All rights
            reserved.
          </p>
          <div className="d-flex gap-4">
            <Link to={"https://twitter.com/itranxit"} target="_blank">
              <Icon
                icon="teenyicons:twitter-solid"
                height={22}
                className="text-white"
              />
            </Link>{" "}
            <Link to={"https://facebook.com/iTranxit"} target="_blank">
              <Icon
                icon="ic:sharp-facebook"
                height={28}
                className="text-white"
              />
            </Link>{" "}
            {/* <Link to={'https://www.instagram.com/itranxit/'} target="_blank">
              <Icon icon="formkit:github" height={22} className="text-white" />{' '}
            </Link> */}
            <Link to={"https://www.instagram.com/itranxit/"} target="_blank">
              <Icon
                icon="teenyicons:instagram-solid"
                height={22}
                className="text-white"
              />{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
